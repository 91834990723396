@tailwind base;
@tailwind components;
@tailwind utilities;


.wrapper{
    width: 100%;
    max-width: 2100px;
    margin: 0 auto;
    overflow: hidden;
    /* overflow-x: auto; */
}

.open{
    display: block;
}

.hide{
    display: none;
}

.main{
    position: relative;
    width: 90%;
    margin: 0 auto;
    height: 670px;
}

.linda{
    position: absolute;
    top: 30px;
}

.linda_img{
    width: 500px;
    height: auto;
}

@media screen and (max-width: 1021px){
    .linda{
        top: 100px;
    }
    .linda_img{
        width: 300px;
    }

    .main{
        height: 500px;
    }
}

@media screen and (max-width: 640px){
    .linda{
        top: 130px;
        left: -100px;
    }

}

.bubles{
    position: absolute;
    right: 100px;
    bottom: 50px;
    opacity: 0.4;
}

.bubles_img{
    width: 500px;
    height: auto;
}


.title{
    font-family: "Lexend", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

/* call btn */

.floatingButton {
    position: fixed;
    bottom: 30px;
    right: 30px; 
    z-index: 999;
    cursor: pointer;
    transition-duration: 300ms;
}

@media screen and (max-width: 640px){
    .floatingButton{
        right: 15px;
        bottom: 10px;
    }
    .floatingButton:hover{
        transform: scale(0);
    }
}

.floatBtn{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    animation: floatBtnAnim 2s infinite;
}

@keyframes floatBtnAnim {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.floatingButton:hover{
    transform: scale(1.05);
}

/* call bth finish */


.arrowAccOpen{
    transform: rotate(-90deg);
}

.textAccOpen{
    height: 100px;
}

.linegrad{
    width: 100%;
    height: 8px;
    background: rgb(78,172,153);
    background: linear-gradient(90deg, rgba(78,172,153,1) 0%, rgba(255,255,255,1) 98%);
}


::-webkit-scrollbar{
    @apply w-2
}

::-webkit-scrollbar-track{
    @apply bg-gray-200
}

::-webkit-scrollbar-thumb{
    border: 2px solid rgb(148 163 184);
    @apply bg-main cursor-pointer
}