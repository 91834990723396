.linegrad {
    width: 100%;
    height: 8px;
    background: rgb(78, 172, 153);
    background: linear-gradient(90deg, rgba(78, 172, 153, 1) 0%, rgba(255, 255, 255, 1) 98%);
}

.custom-list {
    list-style: none;
    /* Убираем стандартные маркеры */
    padding-left: 0;
    /* Убираем отступ */
}

.custom-list-item {
    position: relative;
    /* Для позиционирования ::before */
    padding-left: 30px;
    /* Отступ для маркера */
}

.custom-list-item::before {
    content: '';
    /* Необходимо для отображения */
    position: absolute;
    left: 0;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: #4EAC99;
    /* Цвет кружочка */
    border-radius: 50%;
    /* Делаем кружочком */
}