.back{
    background-image: url('../../assets/background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


@media screen and (max-width: 640px) {
    .back{
        background-image: none;
    }
}